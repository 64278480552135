import React, {  useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";

const MainDashboard = () => {
    return (
      <React.Fragment>
          <Dashboard/>
      </React.Fragment>
    );
  
}

export default MainDashboard;
