// example clauses
const clauses = [
    { id: '1', title: "Clause 1: Confidentiality", content: "This clause explains the confidentiality obligations of the parties." },
    { id: '2', title: "Clause 2: Intellectual Property", content: "This clause explains the intellectual property rights of the parties." },
    { id: '3', title: "Clause 3: Termination", content: "This clause explains the termination conditions of the agreement." },
    { id: '4', title: "Clause 4: Liability", content: "This clause explains the liability limitations of the parties." },
    { id: '5', title: "Clause 5: Governing Law", content: "This clause explains the governing law of the agreement." },
];

export function createClausesObjFromResponse(response) {
    console.log(response);
}

export function uploadFile(file) {
    if (file === null) return;
    
    var filecontent = ''

    var reader = new FileReader();

    reader.addEventListener('load', function(e) {
        filecontent = e.target.result;
    });

    reader.readAsDataURL(file);

    reader.onloadend = () => {
        var requestParams = {
            'filename': file.name,
            'data': filecontent
        }
    
        fetch("https://api.vidhik.ai/save_pdf", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(requestParams)
        }).then(response => response.json())
        .then(response => {
            if(response.success === true) {
                extractClauses(file.name);
            }
        });
    };
}

export function extractClauses(filename) {
    var extractClauseRequest = {
        'filename': filename
    };

    fetch("https://api.vidhik.ai/extract_clauses", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
        body: JSON.stringify(extractClauseRequest)
      }).then(response => response.json())
      .then(jsonResponse => console.log(jsonResponse['data'][0]));
}
