import React from 'react';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

export default function Clause(props) {
    return (
        <AccordionItem>
            <AccordionHeader targetId={props.id}>{props.title}</AccordionHeader>
            <AccordionBody accordionId={props.id}>
                {props.content}
            </AccordionBody>
        </AccordionItem>
    )
}
