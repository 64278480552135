'use client';
import React from 'react';

import {Document, Page} from 'react-pdf';
import * as pdfjs from 'pdfjs-dist/webpack.mjs';
import { useCallback } from 'react';
import {Container, Row, Col, Accordion, Spinner } from "reactstrap";
import {uploadFile, extractClauses, createClausesObjFromResponse} from '../../utils/clauses_utils.mjs';

import Clause from './Clause';
import {Box, Typography} from '@mui/material';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


export default function Dashboard(props) {
    const userId = props.username;
    const maxWidth = 800;

    const [file, setFile] = React.useState('');
    const [containerWidth, setContainerWidth] = React.useState();
    const [numPages, setNumPages] = React.useState();
    const [open, setOpen] = React.useState('1');
    const [clausesExtracted, setClausesExtracted] = React.useState(0);
    const [clauses, setClauses] = React.useState([]);

    const onResize = useCallback((entries) => {
        const [entry] = entries;
    
        if (entry) {
          setContainerWidth(entry.contentRect.width);
        }
      }, []);

    async function onFileChange(event) {
        const { files } = event.target;

        if (files && files[0]) {
            setFile(files[0] || null);
        }

        if(uploadFile(files[0]) === true) {
            console.log("file uploaded");
            var clauses = extractClauses(files[0].name);
            createClausesObjFromResponse(clauses);
            // setClauses(clauses);
            setClausesExtracted(1);
        };
    }
    
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    }

    return (
        <Container className="flex flex-row bg-gradient-to-r from-blue-100 to-blue-300">
            <Row>
                <Col>
                    <Box className="p-1 m-2 bg-white shadow-lg rounded-lg min-h-[calc(100vh-63px)] max-h-[calc(100vh-63px)] overflow-auto">
                            <Typography variant="h6" className="mb-4">Clauses</Typography>

                            {
                                !clausesExtracted && !file &&

                                <h6>Select a file to extract clause</h6>
                            }

                            {!clausesExtracted && file &&
                                <Row>
                                    <Col>
                                        <Spinner color="success">
                                        </Spinner>
                                    </Col>
                                    <Col>
                                        <h4>Extracting clauses from pdf</h4>
                                    </Col> 
                                </Row>

                            }
                            
                            {clausesExtracted ? 
                                <Accordion open={open} toggle={toggle}>
                                    {clauses.map((clause, index) => (
                                        <Clause key = {index} id={clause.id} title={clause.title} content={clause.content} />
                                    ))}
                                </Accordion> : null
                            }
                        </Box>
                </Col>

                <Col>
                    <Box className="p-1 m-2 bg-white shadow-lg rounded-lg min-h-[calc(100vh-63px)] max-h-[calc(100vh-63px)] overflow-auto">
                        <label htmlFor="file">Load from file:</label>{' '}
                            <input onChange={onFileChange} type="file" />
                        <Document
                            file={file}
                            onLoadError={console.error}
                            onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                    />
                                ))}
                        </Document>
                    </Box>
                </Col>
            </Row>

        </Container>
    )
}
