import React, {  useState } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Hero from "./hero";
import AboutUs from "../../components/AboutUs/AboutUs";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/footer";

const Home = () => {
  const [state] = useState({
    navItems: [
      { id: 1, idnm: "home", navheading: "Home" },
      { id: 3, idnm: "features", navheading: "Features" },
      { id: 7, idnm: "contact", navheading: "Contact" },
    ],
    navClass: "",
  });

  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <NavbarPage navItems={state.navItems} navClass={state.navClass} />

      {/* here section */}
      <Hero />
      {/* section */}
      <Section />


      {/* about us */}
      <AboutUs />

      {/* contact */}
      <Contact />

      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Home;
