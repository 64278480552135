import React from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";
import AuthProvider from "./context/useAuth";

function App() {
  return (
    <React.Fragment>
      <AuthProvider>
        <Routes>
          {routes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
        </Routes>
      </AuthProvider>
    </React.Fragment>
  );
}

export default App;
