import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "./context/useAuth";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const { token } = useAuth();

    console.log(token);
    return !!token ? (
        <>{children}</>
     ): (
            <Navigate to="/login" state={{from: location}} replace />
    );
};

export default ProtectedRoute;
