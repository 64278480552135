import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="vertical-content">
            <Col lg={5}>
              <div className="features-box">
                <h3>
                  We use power of llm to help you extract information
                  from pdf and build automated workflows
                </h3>
                <p className="text-muted web-desc">
                  Vidhik processess pdf documents of any type and extract relevant
                  information that can be used to automate compliance, legal reports
                </p>
                <ul className="text-muted list-unstyled mt-4 features-item-list">
                  <li className="">Pdf containing text, images, tables and structure</li>
                  <li className="">
                    Ask your questions and Vidhik will answer questions based on information in pdf
                  </li>
                  <li className="">
                    Extract and download the information in a query-able format.
                  </li>
                </ul>
                <Link
                  to="#"
                  className="btn btn-primary mt-4 waves-effect waves-light"
                >
                  Learn More <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </Col>
            <Col lg={7}>
              <div className="features-img features-right text-end">
                <img
                  src="assets/images/online-world.svg"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
