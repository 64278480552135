import React from "react";

import Index1 from "./pages/Home/Home";

//Auth Pages
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import PasswordForget from "./pages/Auth/PasswordForget";

//Dashboard Pages
import MainDashboard from "./pages/Dashboard/MainDashboard";
import ProtectedRoute from "./ProtectedRoutes";

const routes = [
  { path: "/password-forget", component: <PasswordForget /> },
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <Signup /> },
  {path: "/dashboard", component: <ProtectedRoute><MainDashboard /></ProtectedRoute>},

  { path: "/", component: <Index1 /> },
];

export default routes;
