import React from "react";
import { Container, Row, Col } from "reactstrap";


const Hero = () => {

  return (
    <React.Fragment>
        <div className="hero">
            <img src={process.env.PUBLIC_URL + '/assets/images/brand.gif'} height={400} width={400}alt="Extrtact"/>
        </div>
    </React.Fragment>
  );
};

export default Hero;
